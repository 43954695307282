import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import How from "../components/how"
import Cta from "../components/cta"

const Services = () => (
  <Layout>
    <Seo title="Our Services" />
    <section className="services">
      <div className="container">
        <div className="grid">
          <div className="column-xs-12">
            <h4>Providing Top Quality of Workmanship</h4>
            <h2>Years of experience allows us at Luis's Painting to meet all details of each project from start to finish.</h2>
            <p>Need a highly professional, dependable, and experienced painting contractor? You can be assured that each job by the team at Luis's Painting will get done the right way. We offer a range of services to meet your project needs. We are a reliable, professional local business that you can call for all of your painting needs. <Link to='/contact' className="page-link">Contact us</Link> and we can talk about the details.</p>
          </div>
        </div>
        <div className="grid">
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m16 2.672-5.331 5.331V5.87H6.404v6.398l-3.755 3.755.754.754L16 4.18l12.597 12.597.754-.754L16 2.672zM7.47 6.937h2.132v2.132l-2.133 2.133V6.937z"/><path d="M6.404 16.533v12.795h7.464v-7.464h4.265v7.464h7.464V16.533l-9.596-9.596-9.596 9.596zM24.53 28.262h-5.331v-7.464h-6.398v7.464H7.47V16.975L16 8.445l8.53 8.53v11.287z"/></svg>
              </div>
              <div className="details">
                <h3>Interior Painting</h3>
                <p>Thinking of giving a fresh, new look for the interiors of your home or business? We are the painting specialists to contact to transform all that for you. We use only the best and highest quality painting products in the industry. We pay careful attention to detail and are committed to providing exceptional workmanship and results.</p>
                <ul className="service-list">
                  <li>Install and repair texture</li>
                  <li>Install, repair, and remove wallpaper</li>
                  <li>Paint interior surfaces (ceilings, walls, doors, etc.)</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M26.995 12.046h4.258L15.796 2.894.747 12.046h3.857V28.04H2.472v1.066h26.656V28.04h-2.133V12.046zM4.553 10.98l11.25-6.842 11.556 6.842H4.552zM5.67 28.04V12.046h3.199V28.04H5.67zm4.265 0V12.046h3.199V28.04H9.935zm4.265 0V12.046h3.199V28.04H14.2zm4.265 0V12.046h3.199V28.04h-3.199zm4.265 0V12.046h3.199V28.04H22.73z"/></svg>
              </div>
              <div className="details">
                <h3>Exterior Painting</h3>
                <p>Luis's Painting has years of experience in the professional painting business. We will assure you in giving your project the dedication it deserves and leaving the exterior of your home or business having a durable and quality finish with long lasting results.</p>
                <ul className="service-list">
                  <li>Scrape and remove loose painted areas</li>
                  <li>Prime and caulk surfaces</li>
                  <li>Paint trim and siding</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M24.839 15.852a.528.528 0 0 0-.118-.22L15.999 1.086l-1.246 2.077h.002L7.23 15.711a.449.449 0 0 0-.045.097 10.12 10.12 0 0 0-1.315 4.976c0 5.585 4.544 10.129 10.13 10.129s10.129-4.544 10.129-10.129c0-1.721-.446-3.422-1.29-4.932zm-8.838 13.995c-4.997 0-9.064-4.066-9.064-9.063 0-1.584.423-3.151 1.222-4.531a.513.513 0 0 0 .037-.079l7.803-13.013 7.802 13.011a.43.43 0 0 0 .039.081 9.047 9.047 0 0 1 1.222 4.531c0 4.998-4.066 9.063-9.063 9.063z"/></svg>
              </div>
              <div className="details">
                <h3>Power Washing</h3>
                <p>Power washing can enhance the value of your home or business by removing any dirt, mold, and mildew from fences, decks, patios, driveways, vinyl siding, driveways, and other exterior areas. At Luis's Painting we assure you that our power washing services can keep the exterior of your home or business looking clean and new.</p>
                <ul className="service-list">
                  <li>Remove dirt, mold, and mildew</li>
                  <li>Remove loose paint</li>
                  <li>Clean and brighten exterior areas</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M27.995 5.338V3.684a2.611 2.611 0 0 0-2.611-2.611H8.217a2.61 2.61 0 0 0-2.61 2.611v1.654h-.489A2.178 2.178 0 0 0 2.94 7.517v3.649c0 1.203.975 2.179 2.178 2.179l10.037-.003c.613 0 1.113.499 1.113 1.113v3.679h-2.131v12.795h5.331V18.134h-2.134v-3.679a2.18 2.18 0 0 0-2.179-2.179h-1.32v.003H5.119a1.114 1.114 0 0 1-1.112-1.113V7.517c0-.613.499-1.113 1.112-1.113h.489v1.654a2.61 2.61 0 0 0 2.61 2.611h17.167a2.611 2.611 0 0 0 2.611-2.611V6.404h1.066V5.338h-1.066zM18.4 29.861h-3.199V19.198H18.4v10.663zm8.529-21.803c0 .852-.694 1.545-1.545 1.545H8.217a1.546 1.546 0 0 1-1.544-1.545V3.684c0-.852.692-1.545 1.544-1.545h17.167c.852 0 1.545.693 1.545 1.545v4.374z"/></svg>
              </div>
              <div className="details">
                <h3>Decks</h3>
                <p>Our deck cleaning, staining services, and maintenance will bring you peace of mind knowing professionals at Luis's Painting are taking care of it for you. Our expertise and experience in deck restoration will improve the appearance of your deck and lengthen its lifetime.</p>
                <ul className="service-list">
                  <li>Clean and rejuvenate wood</li>
                  <li>Sand and recoat wood surfaces</li>
                  <li>Seal and stain wood surfaces</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32"><path d="m22.347 14.827-10.4-10.453-.213.16v-.267c0-1.76-1.44-3.2-3.2-3.2s-3.2 1.44-3.2 3.2v6.667L.907 15.361a3.122 3.122 0 0 0 0 4.427l6.027 6.027c.587.64 1.44.907 2.24.907s1.6-.32 2.24-.907l7.627-7.68h6.56l-3.253-3.307zM6.4 4.267c0-1.173.96-2.133 2.133-2.133s2.133.96 2.133 2.133V5.6L6.399 9.867v-5.6zm12.213 12.8-8 8c-.373.373-.907.587-1.493.587a2.058 2.058 0 0 1-1.44-.587L1.653 19.04c-.8-.8-.8-2.133 0-2.933l9.013-8.96v6.72h1.067V6.08l.16-.16L23.04 17.067h-4.427zm9.6 9.92c-.32-2.88-3.413-6.72-3.413-6.72s-3.147 3.893-3.413 6.773v.427c0 1.92 1.547 3.467 3.467 3.467s3.467-1.547 3.467-3.467c-.053-.16-.053-.32-.107-.48zm-3.413 2.88a2.39 2.39 0 0 1-2.4-2.4V27.2c.16-1.6 1.387-3.68 2.347-5.12.96 1.387 2.187 3.52 2.4 5.067v.32c.053 1.333-1.013 2.4-2.347 2.4z"/></svg>
              </div>
              <div className="details">
                <h3>Commercial &amp; Residential</h3>
                <p>We understand that you want a trustworthy and respectful painting experts for your projects. With over 20 years of experience, whether the job will be big or small, Luis's Painting is the answer for any size project.</p>
                <ul className="service-list">
                  <li>Patch plaster and drywall</li>
                  <li>Paint and refinish cabinets</li>
                  <li>Paint common areas (hallways, trim, etc.)</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column-xs-12">
            <div className="item left">
              <div className="circle" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m29.532 25.76-5.655-5.655.754-.754-.754-.754-2.261 2.261-3.771-3.77 4.53-4.532a5.579 5.579 0 0 0 1.882.324 5.562 5.562 0 0 0 3.954-1.637 5.569 5.569 0 0 0 1.178-6.174l-.311-.722-2.43 2.43-1.956.027.026-1.866 2.477-2.477-.72-.312a5.576 5.576 0 0 0-2.229-.461 5.552 5.552 0 0 0-3.952 1.637 5.56 5.56 0 0 0-1.311 5.84l-4.529 4.529-6.409-6.408.754-.754-4.145-4.146L2.39 4.647l4.147 4.147.753-.754 6.409 6.408-4.529 4.529a5.57 5.57 0 0 0-1.888-.326 5.558 5.558 0 0 0-3.953 1.637 5.565 5.565 0 0 0-1.176 6.181l.312.72 2.477-2.477 1.865-.025-.027 1.956-2.43 2.43.722.311a5.59 5.59 0 0 0 2.221.458 5.551 5.551 0 0 0 3.952-1.636 5.565 5.565 0 0 0 1.314-5.833l4.532-4.532 3.771 3.769-2.263 2.263.754.754.754-.754 5.654 5.654c.503.504 1.174.781 1.885.781s1.381-.277 1.885-.781a2.668 2.668 0 0 0 0-3.769zM3.899 4.648l.754-.753 2.638 2.638-.754.754-2.639-2.639zm7.549 17.808a4.513 4.513 0 0 1-.955 4.999 4.491 4.491 0 0 1-3.198 1.324c-.347 0-.689-.039-1.021-.116l1.569-1.569.047-3.485-3.394.046-1.619 1.619a4.497 4.497 0 0 1 1.208-4.229 4.495 4.495 0 0 1 3.199-1.325c.626 0 1.233.125 1.806.373l.333.144L20.242 9.418l-.144-.333a4.505 4.505 0 0 1 .952-5.004 4.494 4.494 0 0 1 3.198-1.325c.35 0 .695.04 1.03.117L23.66 4.491l-.047 3.394 3.485-.047 1.57-1.57a4.504 4.504 0 0 1-1.209 4.221 4.499 4.499 0 0 1-3.2 1.325c-.624 0-1.23-.125-1.801-.371l-.332-.143-10.821 10.823.143.332zm17.331 6.319c-.302.302-.704.469-1.131.469s-.829-.167-1.131-.469l-5.654-5.654 2.262-2.262 5.655 5.655a1.6 1.6 0 0 1 .001 2.261z"/></svg>
              </div>
              <div className="details">
                <h3>Handyman Services</h3>
                <p>Are you looking for home repairs to get fixed? Along with our painting services, we also offer handyman services. <Link to='/contact' className="page-link">Contact Luis's Painting</Link> for more information for your handyman project needs.</p>
                <ul className="service-list">
                  <li>Repair and replace tiles</li>
                  <li>Drywall spackling and repair</li>
                  <li>Replace light bulbs</li>
                  <li>Carpentry</li>
                  <li>...and more!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <How />
    <Cta />
  </Layout>
)

export default Services
